.table {
    @apply min-w-full table-auto border;
    border-color: var(--green-light);
}

.table-td {
    @apply px-4 py-2.5 border-b text-sm text-gray-700;
    border-bottom-color: var(--green-light);
}

.table-tr {
    background-color: var(--green);
    @apply whitespace-nowrap
}

.table-th {
    @apply px-4 py-3 border-b text-left text-sm/6 font-semibold text-white
}