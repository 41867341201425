.txt-input {
    @apply px-2 py-2
    block w-full
    rounded-xl border-0
    text-gray-900 placeholder:text-gray-400
    shadow-sm sm:text-sm/6
    ring-1 ring-inset ring-gray-300
}

.txt-input:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--green);
}