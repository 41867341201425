.toolbar {
    @apply bg-white flex flex-row h-[56px] shadow-md px-4 items-center justify-between
}

.toolbar-child-row {
    @apply flex flex-row items-center space-x-4
}

.toolbar-title {
    @apply text-2xl font-bold tracking-tight
}