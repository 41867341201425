.skeleton-card {
    @apply bg-white flex w-full rounded-md shadow-md animate-pulse
}

.skeleton-row {
    @apply flex flex-row items-center
}

.skeleton-text {
    @apply bg-gray-300 rounded-full
}