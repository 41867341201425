.import-status.completed {
    @apply bg-green-100 hover:bg-green-100 text-green-500
    border-[0.5px] border-green-500 border-opacity-50 py-1 px-2 rounded-full
}

.import-status.in_progress {
    @apply bg-orange-100 hover:bg-orange-100 text-orange-500
    border-[0.5px] border-orange-500 border-opacity-50 py-1 px-2 rounded-full
}

.import-status.queued {
    @apply bg-gray-100 hover:bg-gray-100 text-gray-500
    border-[0.5px] border-gray-500 border-opacity-50 py-1 px-2 rounded-full
}

.import-status.error {
    @apply bg-red-100 hover:bg-red-100 text-red-500
    border-[0.5px] border-red-500 border-opacity-50 py-1 px-2 rounded-full
}
